import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import App from './App'
import theme from './theme'
import { ThemeProvider } from '@material-ui/styles'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline/>
    <App/>
  </ThemeProvider>,
  document.querySelector('#root'),
)

// Hot Module Replacement
if (module.hot) {
  module.hot.accept()
}
